const yushupedia = [
	{
		path: "/pedia",
		name: "yushupedia",
		component: () => import("../views/yushupedia/index.vue"),
		// redirect: "/model",
		children: [
			//模型
			{
				path: "/model",
				name: "model",
				component: () => import("../views/yushupedia/model.vue"),
			},
			{
				path: "/subModel",
				name: "subModel",
				component: () => import("../views/yushupedia/subModel.vue"),
			},
		],
	},
	{
		path: "/pediaObj",
		name: "pediaObj",
		component: () => import("../views/yushupedia/pediaObj.vue"),
		// redirect: "/model",
		children: [
			//对象详情
			{
				path: "/detail",
				name: "detail",
				component: () => import("../views/yushupedia/detail.vue"),
			},
			//设备列表
			{
				path: "/list",
				name: "list",
				component: () => import("../views/yushupedia/list.vue"),
			},
			//查看更多
			{
				path: "/more",
				name: "more",
				component: () => import("../views/yushupedia/objMore.vue"),
			},
			//知识库
			{
				path: "/repository",
				name: "repository",
				component: () => import("../views/yushupedia/repository.vue"),
			},
			//没有搜索到内容
			{
				path: "/nocontent",
				name: "nocontent",
				component: () => import("../views/yushupedia/nocontent.vue"),
			}

		],
	},
];

export default yushupedia;
